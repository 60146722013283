import request from '@/utils/ucenterrequest.js'
import edu_request from '@/utils/edu_request'
import pay_request from '@/utils/payRequest'
import qs from 'qs'
export function getLessonRecycleList(params) { // 回收站列表
  return request({
    url: '/personal/recycle/getLessonRecycleList',
    method: 'get',
    params
  })
}
export function throwLessonIntoRecycle(params) { // 删除课程
  return request({
    url: '/personal/recycle/throwLessonIntoRecycle',
    method: 'get',
    params
  })
}
export function takeLessonBack(params) { // 恢复课程
  return request({
    url: '/personal/recycle/takeLessonBack',
    method: 'get',
    params
  })
}
export function get_user_msg(params) { // 获取用户信息
  return request({
    url: '/personal/myinfo/myzhulong',
    method: 'post',
    params
  })
}
export function get_list(params) { // 获取地址列表
  return request({
    url: '/user/address/getUserAddressList',
    method: 'get',
    params
  })
}
export function set_default_address(params) { // 设置默认地址
  return request({
    url: '/user/address/updateAddress',
    method: 'get',
    params
  })
}
export function get_courses_purchased(params) { // 已购课程
  return request({
    url: '/personal/Myinfo/myBuyLesson',
    method: 'post',
    params
  })
}
// 获取我的已过期课程
export function myEndLesson(params) {
  return request({
    url: 'personal/Myinfo/myEndLesson',
    params: params,
    method: 'get'
  })
}
export function to_be_paid(params) { // 待付款课程
  return request({
    url: '/personal/Myinfo/myPayingLesson',
    method: 'get',
    params
  })
}

export function express_list(params) { // 快递列表
  return request({
    url: '/personal/Express',
    method: 'get',
    params
  })
}

export function del_order(params) { // 取消订单
  return request({
    url: '/personal/Myinfo/cancelOrder',
    method: 'get',
    params
  })
}
export function my_fatie(params) { // 我的发帖
  return request({
    url: '/personal/myinfo/myPost',
    method: 'get',
    params
  })
}

export function get_hf_list(params) { // 我的回复
  return request({
    url: '/personal/myinfo/myReplyPost',
    method: 'get',
    params
  })
}

export function get_gzxz_list(params) { // 关注小组
  return request({
    url: '/personal/myinfo/myAttendGroup',
    dataType: 'json',
    method: 'get',
    params
  })
}

export function get_kc_see(params) { // 浏览课程
  return request({
    url: 'personal/History/lessonview',
    dataType: 'json',
    method: 'get',
    params
  })
}

export function get_tz_see(params) { // 浏览帖子personal/Myinfo/myAttendFriend
  return request({
    url: 'personal/History/bbsview',
    dataType: 'json',
    method: 'get',
    params
  })
}

export function get_longyou_list(params) { // 关注龙友
  return request({
    url: 'user/bbs/careOfUser',
    dataType: 'json',
    method: 'get',
    params
  })
}
export function get_pingc_list(params) { // 我的评测
  return request({
    url: 'personal/History/evaluate',
    dataType: 'json',
    method: 'get',
    params
  })
}
export function get_unuse_yhq(params) { // 未使用优惠券gift/gift/logistic
  return request({
    url: 'personal/Coupon/unused',
    dataType: 'json',
    method: 'get',
    params
  })
}
export function get_used_yhq(params) { // 已使用优惠券
  return request({
    url: 'personal/Coupon/used',
    dataType: 'json',
    method: 'get',
    params
  })
}
export function scorelogistic(params) { // 物流详情
  return request({
    url: 'gift/gift/Scorelogistic',
    dataType: 'json',
    method: 'get',
    params
  })
}
export function get_beOverdue_yhq(params) { // 已过期优惠券
  return request({
    url: 'personal/Coupon/expires',
    dataType: 'json',
    method: 'get',
    params
  })
}
export function get_ones_yhq(params) { // 优惠券详情getItNow
  return request({
    url: '/api/coupon/getById',
    dataType: 'json',
    method: 'get',
    params
  })
}
export function getItNow(params) { // 优惠券领取
  return request({
    url: '/api/coupon/receive',
    dataType: 'json',
    method: 'get',
    params
  })
}
export function get_uplist(params) { // 我的上传
  return request({
    url: 'personal/Materials/upload',
    dataType: 'json',
    method: 'get',
    params
  })
}
export function get_down(params) { // 我的下载
  return request({
    url: 'personal/Materials/download',
    dataType: 'json',
    method: 'get',
    params
  })
}
export function outLogin(params) { // 退出登录
  return request({
    url: 'user/logon/signOut',
    dataType: 'json',
    method: 'get',
    params
  })
}
export function get_verification_Code(params) { // 获取用户信息get_verification_CodeZXM
  return request({
    url: '/personal/Myinfo/sendMobileCode',
    method: 'get',
    params
  })
}
export function get_verification_CodeZXM(params) { // 获取用户信息
  return request({
    url: '/user/logon/sendMobileCode',
    method: 'get',
    params
  })
}
export function get_myshoucang(params) { // 获取用户信息/personal/Myinfo/myCollectInvatation
  return request({
    url: '/personal/Myinfo/myCollectLesson',
    method: 'get',
    params
  })
}
export function get_mytiezi(params) { // 获取用户信息
  return request({
    url: '/personal/Myinfo/myCollectInvatation',
    method: 'get',
    params
  })
}
export function get_sheng(params) { // 省
  return request({
    url: '/user/address/getPCCList',
    method: 'get',
    params
  })
}
export function get_shi(params) { // 省get_shi
  return request({
    url: '/user/address/getPCCList',
    method: 'get',
    params
  })
}
export function add_addrss(params) { // 省get_shi
  return request({
    url: '/user/address/editAddress',
    method: 'get',
    params
  })
}
export function dataCollection(params) { // 资料收藏
  return request({
    url: '/personal/Materials/collection',
    method: 'get',
    params
  })
}
export function confirm_receipt(params) { // 确认收货
  return request({
    url: '/personal/Express/confirm',
    method: 'get',
    params
  })
}
export function get_phone(params) { // 获取当前绑定手机号  /user/address/getOneAddressInfo
  return request({
    url: '/personal/Myinfo/getRegisInfo',
    method: 'get',
    params
  })
}

export function getAddressInfo(params) { // 获取地址详情
  return request({
    url: '/user/address/getOneAddressInfo',
    method: 'get',
    params
  })
}

export function get_one_address(params) { // 快递详情
  return request({
    url: '/gift/gift/logistic',
    // url: 'personal/Express/details',
    method: 'get',
    params
  })
}
// 礼品兑换礼品列表
export function get_gift(params) { // 礼品列表
  return request({
    url: '/gift/gift/exchangeGift',
    method: 'get',
    params
  })
}
// 礼品详情
export function get_giftDetail(params) { // 礼品详情
  return request({
    url: '/user/Gift/getGiftInfo',
    method: 'get',
    params
  })
}
// 筑龙币查询
export function getMyScore(params) {
  return request({
    url: '/user/Gift/getMyScore',
    method: 'get',
    params
  })
}
// 兑换地址、规格、地址 信息
export function getGiftMsg(params) {
  return request({
    url: '/user/Gift/getGiftInfo',
    method: 'get',
    params
  })
}
// 根据颜色获取规格
export function getSpecifications(params) {
  return request({
    url: '/user/Gift/getSpecsByColor',
    method: 'get',
    params
  })
}
// 京东 地址列表
export function getAddressList(params) {
  return request({
    url: '/user/Jdaddress/getUserJdAddressList',
    method: 'get',
    params
  })
}
// 京东 删除
export function deleteAddress(params) {
  return request({
    url: '/user/Jdaddress/deleteAddress',
    method: 'get',
    params
  })
}
// 京东设置默认地址
export function set_jdDefault_address(params) {
  return request({
    url: '/user/Jdaddress/setDefaultAddress',
    method: 'get',
    params
  })
}
// 京东添加新地址
export function addjdAddress(params) {
  return request({
    url: '/user/Jdaddress/editAddress',
    method: 'get',
    params
  })
}
// 京东修改地址
export function getOneAddress(params) {
  return request({
    url: '/user/Jdaddress/getOneAddress',
    method: 'get',
    params
  })
}
// 京东获取省
export function getJdProvince(params) {
  return request({
    url: '/user/Jdaddress/getProvince',
    method: 'get',
    params
  })
}
// 京东获取市
export function getJdShi(params) {
  return request({
    url: '/user/Jdaddress/getCity',
    method: 'get',
    params
  })
}
// 京东获取区
export function getJdQu(params) {
  return request({
    url: '/user/Jdaddress/getCounty',
    method: 'get',
    params
  })
}
// 京东获取街道
export function getJdTown(params) {
  return request({
    url: '/user/Jdaddress/getTown',
    method: 'get',
    params
  })
}
// 立即支付（兑换）
export function PayImmediately(params) {
  return request({
    url: '/user/Gift/exchangeGift',
    method: 'get',
    params
  })
}
// 获取礼品分类列表
export function getCategory(params) {
  return request({
    url: '/gift/gift/getCategoryList',
    method: 'get',
    params
  })
}
// 关键词搜索
export function keyWordSearch(params) {
  return request({
    url: '/gift/gift/exchangeGift',
    method: 'get',
    params
  })
}
// 兑换记录
export function ExchangeRecordList(params) {
  return request({
    url: '/gift/gift/exchangeLog',
    method: 'get',
    params
  })
}
// 提交修改邮箱
export function commits_emits(params) {
  return request({
    url: '/personal/myInfo/directSetEmail',
    method: 'get',
    params
  })
}
// 设置密码
export function setPassword(params) {
  return request({
    url: '/personal/Myinfo/updatePassword',
    method: 'get',
    params
  })
}
// 兑换码列表
export function redeemlist(params) {
  return request({
    url: 'redeem/Redeemlist',
    method: 'get',
    params
  })
}
// 领取兑换码
export function getredeem(params) {
  return request({
    url: 'redeem/get',
    method: 'get',
    params
  })
}
// 兑换
export function redeem(params) {
  return request({
    url: 'redeem/redeem',
    method: 'get',
    params
  })
}
// 兑换码查找单个兑换码
export function productinfo(params) {
  return request({
    url: 'redeem/product/codeinfo',
    method: 'get',
    params
  })
}
// id查找单个兑换码
export function productm(params) {
  return request({
    url: 'redeem/product/info',
    method: 'get',
    params
  })
}

// 获取积分名称
export function getRow(params) {
  return request({
    url: 'setting/bbsscore/getRow',
    method: 'get',
    params
  })
}

// 确认修改地址
export function editOrderAddress(params) {
  return request({
    url: 'personal/Myinfo/editOrderAddress',
    method: 'get',
    params
  })
}
// 免费课程列表
export function myFreeLesson(params) {
  return request({
    url: 'personal/Myinfo/myFreeLesson',
    method: 'get',
    params: params
  })
}

// 满意度调查（获取）
export function getUserPostSatisfaction(params) {
  return request({
    url: 'personal/myinfo/getUserPostSatisfaction',
    method: 'get',
    params: params
  })
}
// 满意度调查（保存）
export function saveUserPostSatisfaction(params) {
  return request({
    url: 'personal/myinfo/saveUserPostSatisfaction',
    method: 'post',
    contentType: 'application/x-www-from-urlencoded',
    data: qs.stringify(params)
  })
}

// 获取协议信息
export function getAgreementInfo(params) {
  return request({
    url: 'personal/Agreement/getAgreementInfo',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 确定协议
export function confirmAgreement(params) {
  return request({
    url: 'personal/Agreement/confirmAgreement',
    method: 'post',
    data: qs.stringify(params)

  })
}
// 我收藏的专题
export function getVipCardCollection(params) {
  return request({
    url: 'personal/Myinfo/getVipCardCollection',
    method: 'get',
    params: params
  })
}
// 我的评测
export function getUserReviewListNew(params) {
  return edu_request({
    url: '/review/review/getUserReviewListNew',
    method: 'get',
    params: params
  })
}

// 我的评测 zkbpro
export function getUserReviewListByZKBPRO(params) {
  return edu_request({
    url: '/review/review/getUserReviewListByZKBPRO',
    method: 'get',
    params: params
  })
}

// 获取邮费及包邮信息
export function getexpensesOption(params) {
  return pay_request({
    url: 'pay/money/getexpensesOption',
    method: 'post',
    params
  })
}
// 支付是否成功
export function isSuccess(params) {
  return pay_request({
    url: 'pay/order/isSuccess',
    method: 'POST',
    params
  })
}

// 获取是否显示直播、课程
export function canShowForMp(params) {
  return edu_request({
    url: '/test/wechatapplet/applet',
    method: 'GET',
    params
  })
}
// 添加帖子搜索记录
export function addSearchHistory(params) {
  return request({
    url: '/personal/myinfo/addSearchHistory',
    method: 'GET',
    params
  })
}
// 获取帖子搜索记录
export function getSearchHistory(params) {
  return request({
    url: '/personal/myinfo/getSearchHistory',
    method: 'GET',
    params
  })
}

// 获取我的首页菜单
export function getMenuList(params) {
  return edu_request({
    url: '/zkb/index/getMenuList',
    method: 'GET',
    params
  })
}
