<template>
  <div>
    <heads :more="showMore" :msg="title_msg"></heads>
    <div class="pass">
      <input v-model="oldPassword" :type="type" placeholder="请输入旧密码" class="ipt" @input="changes">
      <img
        v-show="is_eye"
        class="img"
        src="../../static/images/my_index/eye.png"
        alt
        @click="eye()"
      >
      <img
        v-show="!is_eye"
        class="img"
        src="../../static/images/my_index/biyan.png"
        alt
        @click="eye()"
      >
    </div>
    <div class="pass">
      <input v-model="newPass" :type="type" placeholder="请输入新密码" class="ipt" @input="changes">
      <img
        v-show="is_eye"
        class="img"
        src="../../static/images/my_index/eye.png"
        alt
        @click="eye()"
      >
      <img
        v-show="!is_eye"
        class="img"
        src="../../static/images/my_index/biyan.png"
        alt
        @click="eye()"
      >
    </div>
    <div class="pass">
      <input v-model="qrNewPass" :type="type" placeholder="请确认新密码" class="ipt" @input="changes">
      <img
        v-show="is_eye"
        class="img"
        src="../../static/images/my_index/eye.png"
        alt
        @click="eye()"
      >
      <img
        v-show="!is_eye"
        class="img"
        src="../../static/images/my_index/biyan.png"
        alt
        @click="eye()"
      >
    </div>

    <van-button
      :disabled="isdisabled"
      type="danger"
      style="width: 92%; margin: 30px auto; background: #ff272c"
      block
      @click="determine()"
    >确认</van-button>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import commontop from '@/compontens/menu.vue'
import { setPassword } from '@/api/my_user'
import { Toast } from 'vant'
export default {
  components: {
    heads,
    commontop

  },
  data() {
    return {
      is_eye: false,
      title_msg: '设置密码',
      type: 'password',
      isdisabled: true,
      newPass: '',
      isshow: false,
      oldPassword: '',
      qrNewPass: '',
      showMore: true
    }
  },
  mounted() {

  },
  // updated() {
  //   this.changes()
  // },
  methods: {

    // 确认更改
    determine() {
      if (this.newPass.match(/\s/) != null || this.oldPassword.match(/\s/) != null || this.qrNewPass.match(/\s/) != null) {
        Toast('密码格式错误')
      } else if (this.newPass !== this.qrNewPass) {
        Toast('两次新密码不一致')
      } else {
        var params = {
          current_pass: this.oldPassword,
          new_pass: this.newPass,
          check_pass: this.qrNewPass
        }
        setPassword(params).then(res => {
          if (res.errNo !== 0) {
            Toast(res.msg)
          } else {
            Toast('修改成功')
            this.$router.go(-1)
          }
        })
      }
    },
    clicks() {
      if (this.isshow === false) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    },
    eye() {
      if (this.type === 'password') {
        this.type = 'text'
        this.is_eye = true
      } else {
        this.type = 'password'
        this.is_eye = false
      }
    },
    // 监听全部输入框是否都有值
    changes() {
      console.log(this.oldPassword)
      if (this.newPass && this.oldPassword && this.qrNewPass) {
        this.isdisabled = false
      } else {
        this.isdisabled = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.img_h {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 44px;
  z-index: 1;
}
.ipt {
  width: 640px;
  height: 89px;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  margin: 76px auto;
  display: block;
  padding-left: 43px;
}
.img {
  width: 43px;
  height: 30px;
  position: absolute;
  right: 53px;
  top: 30px;
}
.pass {
  position: relative;
}
</style>

